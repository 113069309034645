<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'Models',
	data() {
		return {
			actions: [
				{
					name: 'Model',
					route: 'create-model',
					icon: 'plus-circle'
				}
			]
		}
	},
	metaInfo() {
		return {
			title: 'Models'
		}
	}
}
</script>
