<template>
	<div>
		<router-view v-if="model.id && !isLoading"></router-view>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { GET, GET_ALL } from '@/store/actions.type'

export default {
	name: 'Model',
	props: {
		id: {
			type: String,
			required: true
		}
	},
	metaInfo() {
		return {
			title: 'Model'
		}
	},
	data() {
		return {
			actions: [{ name: 'Models', route: 'models', icon: 'project-diagram' }]
		}
	},
	computed: {
		...mapState({
			model: (state) => state.models.current,
			connections: (state) => state.connections.all.filter((c) => c.modelId === state.models.current.id)
		}),
		...mapState('ui', ['isLoading']),
		nav: function() {
			return this.$route.matched[2].meta.nav
		}
	},
	mounted() {
		this.fetchModel()
	},
	methods: {
		fetchModel() {
			this.$store.dispatch(`models/${GET}`, this.id).then(() => {
				this.$store.dispatch(`channels/${GET_ALL}`)
				this.$store.dispatch(`creators/${GET_ALL}`)
				this.$store.dispatch(`entities/${GET_ALL}`)
				this.$store.dispatch(`fields/${GET_ALL}`)
				this.$store.dispatch(`connections/${GET_ALL}`)
			})
		}
	}
}
</script>
