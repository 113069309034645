<template>
	<div>
		<div class="bg-gray-50 pt-12 sm:pt-16">
			<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
				<div class="max-w-4xl mx-auto text-center">
					<h2 class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
						{{ account.name }}
					</h2>
				</div>
			</div>
			<onboard-todo
				v-if="!readyForModelConfig && !isLoading"
				:model-count="modelCount"
				:field-count="fieldCount"
				:creator-count="creatorCount"
				:entity-count="entityCount"
				:channel-count="channelCount"
			/>
			<div v-if="!isLoading" class="mt-10 pb-12 bg-white sm:pb-16">
				<div class="relative">
					<div class="absolute inset-0 h-1/2 bg-gray-50"></div>
					<div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div class="max-w-4xl mx-auto">
							<div class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
								<div class="border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
									<div v-if="checkTotalNoDescriptions.count > 0">
										<p class="text-5xl leading-none font-extrabold text-red-600">
											{{ checkTotalNoDescriptions.count }}
											<span class="text-sm font-medium text-gray-500">/{{ checkTotalNoDescriptions.total }}</span>
										</p>
										<p class="mt-2 text-lg leading-6 font-medium text-gray-500">
											Missing Descriptions
										</p>
									</div>
									<div v-else class="text-3xl leading-none text-green-600">
										<p class="mt-2 text-lg leading-6 font-medium text-gray-500">Descriptions Complete</p>
									</div>
								</div>
								<div class="border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
									<div v-if="checkGdpr.count > 0">
										<p class="text-5xl leading-none font-extrabold text-red-600">
											{{ checkGdpr.count }}
											<span class="text-sm font-medium text-gray-500">/{{ checkGdpr.total }}</span>
										</p>
										<p class="mt-2 text-lg leading-6 font-medium text-gray-500">
											Missing GDPR Purpose
										</p>
									</div>
									<div v-else class="text-3xl leading-none text-green-600">
										<p class="mt-2 text-lg leading-6 font-medium text-gray-500">GDPR Purpose Complete</p>
									</div>
								</div>
								<div class="border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
									<div v-if="checkIncomplete.count > 0">
										<p class="text-5xl leading-none font-extrabold text-red-600">
											{{ checkIncomplete.count }}
											<span class="text-sm font-medium text-gray-500">/{{ checkIncomplete.total }}</span>
										</p>
										<p class="mt-2 text-lg leading-6 font-medium text-gray-500">
											Incomplete Connections
										</p>
									</div>
									<div v-else class="text-3xl text-green-600 flex items-center">
										<p class="mt-2 text-lg leading-6 font-medium text-gray-500">Connections Complete</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="fieldsGdpr.length" class="mt-6 grid grid-cols-1 row-gap-8">
					<div
						id="product-application-ui-headings"
						class="border-t border-gray-200 pt-8 grid grid-cols-1 row-gap-6 lg:grid-cols-4 lg:gap-5"
					>
						<div class="mr-5">
							<h3 class="text-lg leading-7 font-medium tracking-tight text-gray-900">
								GDPR Report
							</h3>
							<router-link class="text-xs text-gray-300" :to="{ name: 'fields' }">
								Edit Fields
							</router-link>
						</div>
						<div class="grid grid-cols-1 row-gap-8 sm:col-gap-5 sm:row-gap-6 col-span-3">
							<gdpr-table :fields-gdpr="fieldsGdpr" />
						</div>
					</div>
				</div>
				<div v-if="notUsed.length" class="mt-6 grid grid-cols-1 row-gap-8">
					<div
						id="product-application-ui-headings"
						class="border-t border-gray-200 pt-8 grid grid-cols-1 row-gap-6 lg:grid-cols-4 lg:gap-5"
					>
						<div class="mr-5">
							<h3 class="text-lg leading-7 font-medium tracking-tight text-gray-900">
								Not Used Report
							</h3>
							<p class="text-xs text-gray-300">These items exist, but aren't used in any models</p>
						</div>
						<div class="grid grid-cols-1 row-gap-8 sm:col-gap-5 sm:row-gap-6 col-span-3">
							<not-used-table :not-used-fields="notUsed" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--Section: Admin Cards-->
	</div>
</template>

<script>
import { GET, GET_ALL } from '@/store/actions.type'
import { mapState, mapGetters } from 'vuex'
import onboardTodo from '@/components/shared/onboardTodo'
import gdprTable from '@/components/dashboard/gdpr'
import notUsedTable from '@/components/dashboard/notUsed'
//import firebase from 'firebase/app'
export default {
	name: 'DashboardOverview',
	components: {
		onboardTodo,
		gdprTable,
		notUsedTable
	},
	data: function() {
		return {
			loadData: {},
			descriptionData: [],
			collectionNames: ['creators', 'fields', 'entities', 'channels', 'models']
		}
	},
	computed: {
		...mapState('accounts', {
			account: (state) => state.current
		}),
		...mapState({
			modelCount: (state) => state.models.all.length,
			fieldCount: (state) => state.fields.all.length,
			channelCount: (state) => state.channels.all.length,
			creatorCount: (state) => state.creators.all.length,
			entityCount: (state) => state.entities.all.length
		}),
		...mapState('ui', ['isLoading']),
		...mapGetters({
			modelsNoDescriptionsCount: 'models/noDescriptionsCount',
			creatorsNoDescriptionsCount: 'creators/noDescriptionsCount',
			fieldsNoDescriptionsCount: 'fields/noDescriptionsCount',
			fieldsGdprCount: 'fields/fieldGdprPurposeCount',
			fieldsGdpr: 'fields/gdprFields',
			fieldsConnections: 'fields/connections',
			fieldsIncompleteConnections: 'fields/incompleteConnections',
			entitiesConnections: 'entities/connections',
			entitiesIncompleteConnections: 'entities/incompleteConnections',
			channelsConnections: 'channels/connections',
			channelsIncompleteConnections: 'channels/incompleteConnections',
			creatorsConnections: 'creators/connections',
			creatorsIncompleteConnections: 'creators/incompleteConnections',
			entitiesNoDescriptionsCount: 'entities/noDescriptionsCount',
			channelsNoDescriptionsCount: 'channels/noDescriptionsCount',
			entitiesNotUsedCount: 'entities/notUsedCount',
			channelsNotUsedCount: 'channels/notUsedCount',
			fieldsNotUsedCount: 'fields/notUsedCount',
			creatorsNotUsedCount: 'creators/notUsedCount',
			entitiesNotUsed: 'entities/notUsed',
			channelsNotUsed: 'channels/notUsed',
			fieldsNotUsed: 'fields/notUsed',
			creatorsNotUsed: 'creators/notUsed'
		}),
		notUsed() {
			return [
				...this.creatorsNotUsed.map((x) => {
					return {
						typeName: 'Creator',
						...x
					}
				}),
				...this.fieldsNotUsed.map((x) => {
					return {
						typeName: 'Field',
						...x
					}
				}),
				...this.entitiesNotUsed.map((x) => {
					return {
						typeName: 'Entity',
						...x
					}
				}),
				...this.channelsNotUsed.map((x) => {
					return {
						typeName: 'channels',
						...x
					}
				})
			]
		},
		readyForModelConfig() {
			return this.modelCount > 0 && this.fieldCount > 0 && this.channelCount > 0 && this.creatorCount > 0 && this.entityCount > 0
		},
		checkTotalNoDescriptions() {
			let total = 0
			let nonDescript = 0

			this.collectionNames.map((collection) => {
				total += this[`${collection}NoDescriptionsCount`].total
				nonDescript += this[`${collection}NoDescriptionsCount`].count
			})

			return { style: this.asStyle(nonDescript, total), count: nonDescript, total }
		},
		checkGdpr() {
			let total = this.fieldsGdprCount.total
			let nonDescript = this.fieldsGdprCount.count
			return { style: this.asStyle(nonDescript, total), count: nonDescript, total }
		},
		checkIncomplete() {
			let count = 0
			let total = 0
			let connectionItems = ['fields', 'entities', 'channels', 'creators']
			connectionItems.map((collection) => {
				total += this[`${collection}Connections`].length
				count += this[`${collection}IncompleteConnections`].length
			})

			return { style: this.asStyle(count, total), count, total }
		}
	},
	mounted() {
		this.fetchData()

		this.$analytics.logEvent('isReadyForConfig', { ready: this.readyForModelConfig })
	},
	metaInfo() {
		return {
			title: 'Dashboard Overview'
		}
	},
	methods: {
		async fetchData() {
			this.$store.dispatch(`accounts/${GET}`, this.$route.params.account).then(() => {
				this.collectionNames.map((collection) => this.$store.dispatch(`${collection}/${GET_ALL}`))
				this.$store.dispatch(`connections/${GET_ALL}`)
			})
		},
		asStyle(count, total) {
			let sum = Math.round((count / total) * 100)
			return `width: ${sum}%`
		}
	}
}
</script>
