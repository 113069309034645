<template>
	<div class="bg-white shadow overflow-hidden sm:rounded-md mt-5">
		<ul>
			<li v-for="(model, i) in models" :key="model.id" :class="i > 0 ? 'border-t border-gray-200' : ''">
				<router-link
					:to="{ name: 'model', params: { id: `${model.id}`, name: `${model._name}` } }"
					class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
				>
					<div class="px-4 py-4 sm:px-6">
						<div class="flex items-center ">
							<font-awesome-icon class="mr-2 text-indigo-600 " :icon="['fad', 'layer-group']" size="lg" />
							<div class="text-lg leading-5 font-medium truncate">
								{{ model.name }}
							</div>
						</div>
						<div class="mt-2 sm:flex sm:justify-between">
							<div class="sm:flex">
								<div class="mr-6 flex items-center text-sm leading-5 text-gray-500">
									{{ model.description }}
								</div>
							</div>
						</div>
					</div>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'ModelList',
	props: {
		models: {
			type: Array,
			default: function() {
				return []
			}
		}
	}
}
</script>
