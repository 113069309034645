<template>
	<div class="mt-5 flex flex-col">
		<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
			<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
				<table class="min-w-full">
					<thead>
						<tr>
							<th class="thead">
								Name
							</th>
							<th class="thead">
								GDPR Status
							</th>
							<th class="thead">
								GDPR Purpose
							</th>
							<th class="thead">
								Retention Period
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="field in fieldsGdpr" :key="field.id" class="bg-white tr">
							<td class="td font-medium text-gray-900">
								{{ field.name }}
							</td>
							<td class="td">
								<span :class="getGdpr(field.gdprStatus).style" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
									{{ getGdpr(field.gdprStatus).text }}
								</span>
							</td>
							<td class="td max-w-xs">
								<p>{{ field.gdprPurpose }}</p>
							</td>
							<td class="td">{{ field.retentionPeriod }} {{ field.retentionPeriodScope }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DashboardGdpr',
	props: {
		fieldsGdpr: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		getGdpr(status) {
			switch (status) {
				case 'sensitive':
					return { style: 'bg-red-100 text-red-800', text: 'Sensitive' }
				case 'personal':
					return { style: 'bg-orange-100 text-orange-800', text: 'Personal' }
				default:
					return { style: 'bg-green-100 text-green-800', text: 'N/A' }
			}
		}
	}
}
</script>
