<template>
	<div class="max-w-screen-xl mx-auto m-5">
		<div class="m-5 bg-blue-50 border-indigo-300 rounded-lg shadow-md border-2 px-4 py-3">
			<h3 class="w-full text-center leading-6 text-base font-semibold uppercase text-gray-600 tracking-wider">
				To Get Started, you need to build out some assets
			</h3>
			<div class="lg:flex ">
				<div class="leading-6 lg:w-1/2 m-5 text-gray-500 text-sm">
					<p>
						<em>Assets</em>
						are the items which will connect together in your models.
					</p>
					<p class="mt-5">
						You can either use the menu at the top of the page, which will always be there. Alternatively, you can use these handy links
					</p>
				</div>
				<div class="lg:w-1/2 m-5 grid grid-cols-2 gap-2 md:grid-cols-3 ">
					<router-link v-if="!modelCount" :to="{ name: 'models' }" tag="button" class="btn btn-indigo">
						Model
					</router-link>
					<router-link v-if="!creatorCount" :to="{ name: 'creators' }" tag="button" class="btn btn-indigo">
						Creator
					</router-link>
					<router-link v-if="!entityCount" :to="{ name: 'entities' }" tag="button" class="btn btn-indigo">
						Entity
					</router-link>
					<router-link v-if="!fieldCount" :to="{ name: 'fields' }" tag="button" class="btn btn-indigo">
						Field
					</router-link>
					<router-link v-if="!channelCount" :to="{ name: 'channels' }" tag="button" class="btn btn-indigo">
						Channel
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OnboardTodo',
	props: {
		modelCount: {
			type: Number,
			default: 0
		},
		fieldCount: {
			type: Number,
			default: 0
		},
		channelCount: {
			type: Number,
			default: 0
		},
		creatorCount: {
			type: Number,
			default: 0
		},
		entityCount: {
			type: Number,
			default: 0
		}
	}
}
</script>

<style></style>
