<template>
	<section>
		<top-bar title="All Models" :action="{ name: 'Create Model', to: { name: 'create-model' } }" />
		<div v-if="!isLoading">
			<empty v-if="!all.length" />
			<list v-else :models="all" />
		</div>
	</section>
</template>

<script>
import List from './List'
import { mapState } from 'vuex'
import { GET_ALL } from '@/store/actions.type'
import topBar from '@/components/shared/topBar'
import Empty from './Empty'
export default {
	name: 'ManageModel',
	components: {
		List,
		Empty,
		topBar
	},
	computed: {
		...mapState('models', ['all']),
		...mapState('ui', ['isLoading', 'refCount'])
	},
	created() {
		this.fetchModels()
	},
	methods: {
		fetchModels() {
			this.$store.dispatch(`models/${GET_ALL}`)
		}
	}
}
</script>
